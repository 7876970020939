import NextLink from 'next/link';
import { Box, Center, Text, VStack, Link } from '@chakra-ui/react';
export default function Custom404() {
  return (
    <Box mt={30}>
      <Center>
        <VStack>
          <Text mb={8} fontSize="lg">
            お探しのページが見つかりませんでした。
          </Text>
          <NextLink href="/" passHref>
            <Link>トップページへ</Link>
          </NextLink>
        </VStack>
      </Center>
    </Box>
  );
}
